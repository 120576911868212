import React from "react";
import { Text, Button } from "@aidron/aidron-ds";
import { getNextLevel } from "../../../../utils/utils";

import * as S from "./MainIndexes.style";

import CustomCard from "../../../../components/StyledComponents/CustomCard/CustomCard.style";
import CustomHeading from "../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import SumCard from "./SumCard/SumCard";
import MultiSlideCarousel from "../../../PlanVsForecast/ConsolidatedView/SecondaryChartsSection/SecondaryCharts/MultiSlideCarousel/MultiSlideCarousel";
import HierarchySumBox from "./HierarchySumBox/HierarchySumBox";
import ContentLoader from "../../../../components/ContentLoader/ContentLoader";

export default function MainIndexes({ selectedGroup, formatIndex, defineProductFilter, selectedLevels, products, predictions }) {
  const selectedLevelNumber = selectedGroup.level[selectedGroup.level.length - 1];
  const nextLevel = `level${Number(selectedLevelNumber) + 1}`;
  const nextLevelGroups = getNextLevel(selectedGroup, nextLevel, products);

  const filteredPredictions = predictions.filter(prediction => selectedGroup.productIds.includes(prediction.productId));

  const clientPlanSum = filteredPredictions.reduce((acc, curr) => {
    if (curr.clientPrediction) return acc + Number(curr.clientPrediction);
    else return acc;
  }, 0);

  const newPlanSum = filteredPredictions.reduce((acc, curr) => {
    const property = curr.editedNewPlan ? "editedNewPlan" : "newPlan";
    if (curr[property]) return acc + Number(curr[property]);
    else return acc;
  }, 0);

  const difference = clientPlanSum - newPlanSum;

  function isButtonClicked(groupName) {
    return selectedLevels[nextLevel] && selectedLevels[nextLevel].includes(groupName);
  }

  function handleButtonClick(groupName) {
    if (isButtonClicked(groupName)) {
      defineProductFilter({});
    } else {
      return defineProductFilter({ [nextLevel]: [groupName] });
    }
  }

  return (
    <S.OuterContainer>
      <S.CardsContainer>
        <SumCard icon="file-text" number={clientPlanSum} numberColor="mediumGrey" label="Plano Atual" formatIndex={formatIndex} />
        <SumCard icon="target" number={newPlanSum} numberColor="orange" label="Novo Plano" formatIndex={formatIndex} />
        <SumCard
          icon="dollar-sign"
          number={difference}
          numberColor="mediumGrey"
          label="Diferença do Plano"
          formatIndex={formatIndex}
          prefix={difference > 0 ? "-" : "+"}
        />
      </S.CardsContainer>

      <CustomCard border="unset" padding="5px 16px 5px 150px">
        <CustomHeading size="xs" color="mediumGrey" margin="0 0 5px 0" textAlign="center">
          Total {selectedGroup.displayName}
        </CustomHeading>

        <ContentLoader
          resultCondition={selectedGroup}
          contentCondition={filteredPredictions.length}
          renderError={() => <Text>Ocorreu um erro. Por favor, tente novamente ou contate o administrador do sistema.</Text>}
        >
          <MultiSlideCarousel slideCount={nextLevelGroups.length} key={nextLevelGroups}>
            {nextLevelGroups.map((group, index) => (
              <S.Box>
                <HierarchySumBox
                  group={group}
                  key={index}
                  filteredPredictions={filteredPredictions}
                  formatIndex={formatIndex}
                  boxCount={nextLevelGroups.length}
                />
                <S.ButtonContainer>
                  <Button
                    label={isButtonClicked(group.name) ? "Remover filtro" : "Filtrar"}
                    onClick={() => handleButtonClick(group.name)}
                    type={isButtonClicked(group.name) ? "filled" : "outlined"}
                  />
                </S.ButtonContainer>
              </S.Box>
            ))}
          </MultiSlideCarousel>
        </ContentLoader>
      </CustomCard>
    </S.OuterContainer>
  );
}
