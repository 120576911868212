import styled from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  th {
    border-bottom: solid 1px grey;
  }

  td {
    vertical-align: middle;
    border-bottom: solid 1px #dcdcdc;
  }

  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: #a7a7a7 !important;
  }
`;

const Filters = styled.div`
  display: flex;
  gap: 50px;
  align-self: flex-start;
  margin-left: 2rem;
  margin-bottom: 1.5rem;
`;

const FilterTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SelectContainer = styled.div`
  align-self: start;
`;

const CenteredCell = styled.td`
  text-align: center;
  vertical-align: middle;
`;

export { OuterContainer, ContentContainer, Filters, FilterTitle, SelectContainer, CenteredCell };
