import React from "react";
import { toNDecimalsString } from "../../../../../../utils/utils";

import * as S from "./ProductAccordion.style";

import CustomCard from "../../../../../../components/StyledComponents/CustomCard/CustomCard.style";
import OpportunityChart from "../../../../../Opportunity/ProductListItems/ListItem/OpportunityAccordion/OpportunityChart/OpportunityChart";
import CustomText from "../../../../../../components/StyledComponents/CustomText/CustomText.style";
import ProductDriversData from "../../../../../../components/ProductDriversData/ProductDriversData";
import AdjustPlanDriversChart from "./AdjustPlanDriversChart/AdjustPlanDriversChart";
import ProductHierarchyHeading from "../../../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";

export default function ProductAccordion({ forecastId, productId, prediction, columnsCount }) {
  function includePercentSymbol(value) {
    return `${toNDecimalsString(value, 1).toLocaleString("pt-BR")}%`;
  }

  function includePlusOrMinusAndPercent(value) {
    if (value == 0 || (value >= -0.04 && value <= 0.04)) {
      return "0%";
    } else {
      const plusOrMinus = value > 0 ? "+" : "-";
      return `${plusOrMinus} ${includePercentSymbol(Math.abs(value))}`;
    }
  }
  return (
    <S.TableRow>
      <td colSpan={columnsCount}>
        <S.Container>
          <CustomCard boxShadow="unset" padding="10px">
            <ProductHierarchyHeading productId={productId} />
            <S.ChartsRow>
              <S.Column>
                <CustomCard padding="5px 10px">
                  <CustomText weight="bold" textAlign="center" size="15px !important">
                    Demanda do Cliente x Plano
                  </CustomText>
                  <S.InnerChartContainer>
                    <OpportunityChart opportunity={{ forecastId, productId }} hideProductHierarchy />
                  </S.InnerChartContainer>
                </CustomCard>
              </S.Column>

              <S.Column>
                <CustomCard maxHeight="50vh" padding="5px 10px" width="min-content">
                  <ProductDriversData productId={productId}>
                    <AdjustPlanDriversChart />
                  </ProductDriversData>
                </CustomCard>
              </S.Column>

              <S.Column>
                <S.Indexes>
                  <CustomCard padding="5px 10px">
                    <S.Index>
                      <div>
                        <CustomText weight="bold" textAlign="center" size="14px !important">
                          Probabilidade de Problema na Venda
                        </CustomText>
                      </div>
                      <CustomText weight="bold" textAlign="center" size="19px !important" color="orange">
                        {includePercentSymbol(prediction?.saleAnomalyProbability)}
                      </CustomText>
                    </S.Index>
                  </CustomCard>

                  <CustomCard padding="5px 10px">
                    <S.Index>
                      <CustomText weight="bold" textAlign="center" size="14px !important">
                        Diferença Acerto IA x Plano
                      </CustomText>
                      <CustomText weight="bold" textAlign="center" size="19px !important" color="orange">
                        {includePlusOrMinusAndPercent(prediction?.aiAccuracyOverClient)}
                      </CustomText>
                    </S.Index>
                  </CustomCard>
                </S.Indexes>
              </S.Column>
            </S.ChartsRow>
          </CustomCard>
        </S.Container>
      </td>
    </S.TableRow>
  );
}
