import React, { useState } from "react";
import { Text } from "@aidron/aidron-ds";

export default function ColumnLabelCell({
  column,
  upperCellsHeight,
  linesSpan,
  cellLeftBorder,
  cellTopBorder,
  cellRightBorder,
  cellBottomBorder,
  renderSortByButton,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const tooltipText = column.fullLabel;

  return (
    <th
      rowSpan={linesSpan ? linesSpan : "1"}
      className={`subtitle-cell vertical-align-middle column-label-cell ${cellLeftBorder ? "cell-left-border" : ""} ${
        cellTopBorder ? "cell-top-border" : ""
      }
      ${cellRightBorder ? "cell-right-border" : ""}
      ${cellBottomBorder ? "cell-bottom-border" : ""}
      ${column.type === "dif%" || column.type === "dif" ? "white-space-unset" : ""}`}
      style={{ top: `${upperCellsHeight}px` }}
    >
      <div className="d-flex justify-content-center">
        <div
          className={`d-flex align-items-center justify-content-center small-arrow-button table-text width-12px-on-icon height-12px-on-icon position-relative ${
            column.type === "dif%" || column.type === "dif" ? "width-105px" : ""
          }`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Text className="text-center">{column.label}</Text>
          {isHovered && tooltipText && <div className="column-label-tooltip">{tooltipText}</div>}
          {renderSortByButton}
        </div>
      </div>
    </th>
  );
}
