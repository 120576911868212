import React, { useEffect, useState } from "react";
import { Heading, Text, Select, SelectItem } from "@aidron/aidron-ds";

import { getUserEventsByProduct } from "../../../services/UserEventsService";
import { capitalizeFirst } from "../../../utils/utils";

import * as S from "./ProductTable.style";

import CustomCard from "../../../components/StyledComponents/CustomCard/CustomCard.style";
import CustomText from "../../../components/StyledComponents/CustomText/CustomText.style";

export default function ProductTable({ getCompanies }) {
  const [userEventsByProduct, setUserEventsByProduct] = useState({});
  const [selectedCompany, setSelectedCompany] = useState("");

  const eventsByProduct = userEventsByProduct.events ? userEventsByProduct.events : [];
  const hierarchy = getHierarchyNames(userEventsByProduct.hierarchy);

  useEffect(() => {
    if (selectedCompany) {
      fetchEventsByProduct();
    }
  }, [selectedCompany]);

  async function fetchEventsByProduct() {
    const token = localStorage.getItem("token");
    const fetchedEventsByProduct = await getUserEventsByProduct(token, selectedCompany.toLowerCase());
    setUserEventsByProduct(fetchedEventsByProduct);
  }

  function getHierarchyNames(companyObj) {
    const hierarchyNames = { ...companyObj };

    for (let key in hierarchyNames) {
      if (!key.includes("level")) {
        delete hierarchyNames[key];
      }
    }

    return {
      levels: Object.keys(hierarchyNames).sort((a, b) => a.localeCompare(b)),
      labels: hierarchyNames,
    };
  }

  return (
    <>
      <CustomCard width="fit-content" margin="1rem">
        <S.Filters>
          <S.FilterTitle>
            <CustomText size="sm" weight="bold" margin="0 0 1rem 0">
              {selectedCompany ? "Empresa" : "Selecione uma empresa para analisar"}
            </CustomText>
            <S.SelectContainer>
              <Select label="" handleChange={e => setSelectedCompany(e)} selected={selectedCompany}>
                {getCompanies().map(company => (
                  <SelectItem key={company} value={company}>
                    {company}
                  </SelectItem>
                ))}
              </Select>
            </S.SelectContainer>
          </S.FilterTitle>
        </S.Filters>
      </CustomCard>

      <S.OuterContainer>
        {eventsByProduct.length ? (
          <CustomCard margin="0.5rem 0 0 0" width="80%">
            <S.ContentContainer>
              <Heading size="sm" className="mb-2">
                Produtos
              </Heading>

              <table class="table mt-3">
                <thead>
                  <tr>
                    {hierarchy.levels.map(level => (
                      <th key={level}>{hierarchy.labels[level]}</th>
                    ))}
                    <th rowSpan="2">Onde foi visualizado</th>
                    <th rowSpan="2">Quantas vezes</th>
                    <th rowSpan="2">Por quem</th>
                  </tr>
                </thead>
                <tbody>
                  {eventsByProduct.map(event =>
                    event.components.map((component, index) => (
                      <tr key={index}>
                        {index === 0 &&
                          hierarchy.levels.map(level => (
                            <td rowSpan={event.components.length}>
                              <Text>{capitalizeFirst(event.product[level])}</Text>
                            </td>
                          ))}
                        <td>
                          <Text align="center">{component.name}</Text>
                        </td>
                        <S.CenteredCell>
                          <Text>{component.visualizations}</Text>
                        </S.CenteredCell>
                        <td>
                          {component.users.map((user, userIndex) => (
                            <Text key={userIndex}>{user}</Text>
                          ))}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </S.ContentContainer>
          </CustomCard>
        ) : selectedCompany ? (
          <Heading size="sm">A empresa selecionada não teve visualização de produtos.</Heading>
        ) : (
          <></>
        )}
      </S.OuterContainer>
    </>
  );
}
