import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import CompanyProvider from "./templates/context/CompanyContext";
import ForecastProvider from "./templates/context/ForecastContext";

import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import Settings from "./pages/Settings/Settings";
import PlanVsForecast from "./pages/PlanVsForecast/PlanVsForecast";
import Accuracy from "./pages/Accuracy/Accuracy";
import Opportunity from "./pages/Opportunity/Opportunity";
import Insights from "./pages/Insights/Insights";
import DemandVsTarget from "./pages/DemandVsTarget/DemandVsTarget";
import ChannelAccuracy from "./pages/ChannelAccuracy/ChannelAccuracy";
import ChannelOpportunity from "./pages/ChannelOpportunity/ChannelOpportunity";
import Checkout from "./pages/Checkout/Checkout";
import UserActivity from "./pages/UserActivity/UserActivity";
import AdjustPlan from "./pages/AdjustPlan/AdjustPlan";

import GlobalStyles from "./styles/GlobalStyles/GlobalStyles";
import "./styles/molecules.css";
import "./styles/carousel.css";
import "./styles/styles.css";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <CompanyProvider>
          <ForecastProvider>
            <Route path="/home" component={Home} />
            <Route path="/analise/plano" component={PlanVsForecast} />
            <Route path="/analise/assertividadePlano" component={Accuracy} />
            <Route path="/analise/ideias" component={Insights} />
            <Route path="/oportunidades/produtos" component={Opportunity} />
            <Route path="/oportunidades/canais" component={ChannelOpportunity} />
            <Route path="/acoes/checkout" component={Checkout} />
            <Route path="/acoes/ajustarPlano" component={AdjustPlan} />
          </ForecastProvider>
          <Route path="/analise/canais" component={DemandVsTarget} />
          <Route path="/analise/assertividadeCanal" component={ChannelAccuracy} />
          <Route path="/oportunidades/mercado" component={Insights} />
          <Route path="/configuracoes" component={Settings} />
          <Route path="/atividadeDeUsuarios" component={UserActivity} />
        </CompanyProvider>
      </Switch>
      <GlobalStyles />
    </BrowserRouter>
  );
}
