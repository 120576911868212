import styled from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SumLine = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 3px;
`;

const Line = styled.div`
  border-bottom: solid 1px #bdbdbd;
  margin: 0 5% 0 45%;
`;

export { OuterContainer, SumLine, Line };
