import React, { useState, useContext } from "react";
import { CompanyContext } from "../../../templates/context/CompanyContext";
import { accuracyColumnsPast } from "../../../utils/tableColumns";
import { getMonthName } from "../../../utils/utils";

import ColumnTitlesRow from "../../../components/ColumnTitlesRow/ColumnTitlesRow";
import TableControls from "../../../components/TableControls/TableControls";
import AccuracyColumnLabelsRow from "./AccuracyColumnLabelsRow/AccuracyColumnLabelsRow";
import AccuracyRows from "./AccuracyRows/AccuracyRows";
import TableColumnTitle from "../../../components/TableColumnTitle/TableColumnTitle";
import TableWrapper from "../../../components/TableWrapper/TableWrapper";

export default function AccuracyTable({
  freezeColumns,
  monthColumns,
  currentMonth,
  checkedProducts,
  setCheckedProducts,
  sortRowsBy,
  setSortRowsBy,
  showSeasonColumn,
  selectedHierarchyIndexes,
  storesTable,
  storesAccuracyTable,
  unit,
  allHistoricPredictions,
  selectedProductIds,
  predictionData,
}) {
  const { userInfo } = useContext(CompanyContext);
  const { hierarchy } = useContext(CompanyContext);
  const [checkColumnWidth, setCheckColumnWidth] = useState(0);
  const [hierarchyColumnsWidth, setHierarchyColumnsWidth] = useState([]);
  const [monthColumnsWidth, setMonthColumnsWidth] = useState([]);
  const [monthHeaderCellHeight, setMonthHeaderCellHeight] = useState(0);
  const [hierarchyCellHeight, setHierarchyCellHeight] = useState(0);

  const headerHeight = monthHeaderCellHeight + hierarchyCellHeight;
  const wapeCaedu = [
    { month: 7, year: 2023, aidron: 29.89 },
    { month: 8, year: 2023, aidron: 26.68 },
    { month: 9, year: 2023, aidron: 23.32 },
    { month: 10, year: 2023, aidron: 32, client: 36 },
    { month: 11, year: 2023, aidron: 27, client: 34 },
    { month: 12, year: 2023, aidron: 24.33, client: 27.31 },
    { month: 1, year: 2024, aidron: 36, client: 34 },
    { month: 2, year: 2024, aidron: 44.01, client: 33.55 },
    { month: 3, year: 2024, aidron: 41.2, client: 38.5 },
  ];
  const wapeAramis = [
    { month: 7, year: 2023, aidron: 29, client: 33 },
    { month: 8, year: 2023, aidron: 27, client: 30 },
    { month: 9, year: 2023, aidron: 30, client: 26 },
    { month: 10, year: 2023, aidron: 24, client: 28 },
    { month: 11, year: 2023, aidron: 23, client: 24 },
    { month: 12, year: 2023, aidron: 15, client: 21 },
    { month: 1, year: 2024, aidron: 24, client: 28 },
    { month: 2, year: 2024, aidron: 36, client: 30.4 },
    { month: 3, year: 2024, aidron: 19.1, client: 18 },
  ];
  const wape = userInfo.company.id === 3 ? wapeCaedu : wapeAramis;
  const monthColumnsPosition = monthColumnsWidth.map((month, index) => sumPreviousColumnsWidth(index, monthColumnsWidth));

  const initialScrollIndex = monthColumnsPosition[monthColumns.length - 1];

  function evaluateLastCategoryColumn(index) {
    return freezeColumns && !showSeasonColumn && index === defineHierarchyToDisplay().length - 1 ? "right-border-fade" : "";
  }

  function sumPreviousColumnsWidth(currentColumnIndex, columnsWidth) {
    const previousColumnsWidth = columnsWidth ? columnsWidth : [checkColumnWidth].concat(hierarchyColumnsWidth);
    let sum = 0;
    for (let i = 0; i <= currentColumnIndex; i++) {
      sum += previousColumnsWidth[i];
    }
    return sum;
  }

  function defineHierarchyToDisplay() {
    return hierarchy.levels.filter((level, index) => selectedHierarchyIndexes.includes(index));
  }

  function defineLeftCellBorder(monthIndex, columnIndex) {
    if (columnIndex !== 0) return false;
    if (monthIndex === 0) return false;
    else return true;
  }

  return (
    <TableWrapper resultCondition={() => selectedProductIds.length} loadingCondition={() => allHistoricPredictions.length}>
      <TableControls
        freezeColumns={freezeColumns}
        columns={monthColumns}
        columnsWidth={monthColumnsWidth}
        columnsPosition={monthColumnsPosition}
        initialScrollIndex={initialScrollIndex}
      >
        <table id="download-table" className="table table-striped table-hover padding-070rem-on-th-and-td">
          <thead>
            <ColumnTitlesRow
              freezeColumns={freezeColumns}
              showSeasonColumn={showSeasonColumn}
              monthColumns={monthColumns}
              monthHeaderCellHeight={monthHeaderCellHeight}
              setMonthHeaderCellHeight={setMonthHeaderCellHeight}
              setMonthColumnsWidth={setMonthColumnsWidth}
              wape={wape}
              monthHeaderCellsSpan={accuracyColumnsPast().length}
              defineHierarchyToDisplay={defineHierarchyToDisplay}
              currentMonth={currentMonth}
              defineLeftCellBorder={defineLeftCellBorder}
              renderColumnTitleLabel={({ month }) => (
                <TableColumnTitle label={`${getMonthName(month.month.toString())} ${month.year}`} />
              )}
              title="Produtos"
            />
            <AccuracyColumnLabelsRow
              freezeColumns={freezeColumns}
              sumPreviousColumnsWidth={sumPreviousColumnsWidth}
              showSeasonColumn={showSeasonColumn}
              evaluateLastCategoryColumn={evaluateLastCategoryColumn}
              monthColumns={monthColumns}
              monthHeaderCellHeight={monthHeaderCellHeight}
              sortRowsBy={sortRowsBy}
              setSortRowsBy={setSortRowsBy}
              defineHierarchyToDisplay={defineHierarchyToDisplay}
              setHierarchyCellHeight={setHierarchyCellHeight}
              currentMonth={currentMonth}
              pastPeriodColumns={accuracyColumnsPast()}
            />
          </thead>
          <tbody>
            <AccuracyRows
              selectedProductIds={selectedProductIds}
              freezeColumns={freezeColumns}
              monthColumns={monthColumns}
              setCheckColumnWidth={setCheckColumnWidth}
              sumPreviousColumnsWidth={sumPreviousColumnsWidth}
              setHierarchyColumnsWidth={setHierarchyColumnsWidth}
              checkedProducts={checkedProducts}
              setCheckedProducts={setCheckedProducts}
              showSeasonColumn={showSeasonColumn}
              evaluateLastCategoryColumn={evaluateLastCategoryColumn}
              selectedHierarchyIndexes={selectedHierarchyIndexes}
              defineHierarchyToDisplay={defineHierarchyToDisplay}
              headerHeight={headerHeight}
              currentMonth={currentMonth}
              unit={unit}
              allHistoricPredictions={allHistoricPredictions}
              defineLeftCellBorder={defineLeftCellBorder}
              predictionData={predictionData}
            />
          </tbody>
        </table>
      </TableControls>
    </TableWrapper>
  );
}
