import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL || "https://teste.aidron.ai";
const USERS_URL = `${API_URL}/userEvents`;

export async function getUserEvents(token) {
  const headers = { authorization: token };
  let response = await axios.get(USERS_URL, { headers });
  return response.data;
}

export async function getUserEventsByProduct(token, company) {
  const url = `${USERS_URL}/byProducts/?companyName=${company}`;
  const headers = { authorization: token };
  let response = await axios.get(url, { headers });
  return response.data;
}

export async function createUserEvent(token, newUserEvent) {
  const headers = { authorization: token };
  let response = await axios.post(USERS_URL, newUserEvent, { headers });
  return response.data;
}
