import React, { useContext, useState } from "react";
import { CompanyContext } from "../../../templates/context/CompanyContext";
import { getMonthName } from "../../../utils/utils";
import { adjustPlanColumns } from "../../../utils/tableColumns";

import * as S from "./AdjustPlanTable.style";

import TableWrapper from "../../../components/TableWrapper/TableWrapper";
import TableControls from "../../../components/TableControls/TableControls";
import ColumnTitlesRow from "../../../components/ColumnTitlesRow/ColumnTitlesRow";
import TableColumnTitle from "../../../components/TableColumnTitle/TableColumnTitle";
import AdjustPlanColumnLabelsRow from "./AdjustPlanColumnLabelsRow/AdjustPlanColumnLabelsRow";
import AdjustPlanProductRow from "./AdjustPlanColumnLabelsRow/AdjustPlanRows/AdjustPlanProductRow/AdjustPlanProductRow";
import AdjustPlanSubtotalRow from "./AdjustPlanColumnLabelsRow/AdjustPlanRows/AdjustPlanSubtotalRow/AdjustPlanSubtotalRow";

export default function AdjustPlanTable({
  selectedProductIds,
  predictions,
  freezeColumns,
  monthColumns,
  selectedHierarchyIndexes,
  showSeasonColumn,
  sortRowsBy,
  setSortRowsBy,
  unit,
  showInputs,
  inputs,
  setInputs,
  saveLoading,
  setShowInputs,
}) {
  const { hierarchy } = useContext(CompanyContext);
  const [firstColumnWidth, setFirstColumnWidth] = useState(0);
  const [hierarchyColumnsWidth, setHierarchyColumnsWidth] = useState([]);
  const [monthColumnsWidth, setMonthColumnsWidth] = useState([]);
  const [monthHeaderCellHeight, setMonthHeaderCellHeight] = useState(0);
  const [hierarchyCellHeight, setHierarchyCellHeight] = useState(0);

  const headerHeight = monthHeaderCellHeight + hierarchyCellHeight;

  const monthColumnsPosition = monthColumnsWidth.map((month, index) => sumPreviousColumnsWidth(index, monthColumnsWidth));

  function evaluateLastCategoryColumn(index) {
    return freezeColumns && !showSeasonColumn && index === defineHierarchyToDisplay().length - 1 ? "right-border-fade" : "";
  }

  function sumPreviousColumnsWidth(currentColumnIndex, columnsWidth) {
    const previousColumnsWidth = columnsWidth ? columnsWidth : [firstColumnWidth].concat(hierarchyColumnsWidth);
    let sum = 0;
    for (let i = 0; i <= currentColumnIndex; i++) {
      sum += previousColumnsWidth[i];
    }
    return sum;
  }

  function defineHierarchyToDisplay() {
    return hierarchy.levels.filter((level, index) => selectedHierarchyIndexes.includes(index));
  }

  function defineLeftCellBorder(monthIndex, columnIndex) {
    if (columnIndex !== 0) return false;
    if (monthIndex === 0) return false;
    else return true;
  }

  return (
    <TableWrapper resultCondition={() => selectedProductIds.length} loadingCondition={() => predictions.length}>
      <TableControls
        freezeColumns={freezeColumns}
        columns={monthColumns}
        columnsWidth={monthColumnsWidth}
        columnsPosition={monthColumnsPosition}
        hideControls
      >
        <table id="download-table" className="table table-striped table-hover">
          <S.TableHead>
            <ColumnTitlesRow
              freezeColumns={freezeColumns}
              showSeasonColumn={showSeasonColumn}
              monthColumns={monthColumns}
              monthHeaderCellHeight={monthHeaderCellHeight}
              setMonthHeaderCellHeight={setMonthHeaderCellHeight}
              setMonthColumnsWidth={setMonthColumnsWidth}
              monthHeaderCellsSpan={adjustPlanColumns.length}
              defineHierarchyToDisplay={defineHierarchyToDisplay}
              defineLeftCellBorder={defineLeftCellBorder}
              renderColumnTitleLabel={({ month }) => (
                <TableColumnTitle label={`${getMonthName(month.month.toString())} ${month.year}`} />
              )}
              title="Produtos"
            />
            <AdjustPlanColumnLabelsRow
              freezeColumns={freezeColumns}
              sumPreviousColumnsWidth={sumPreviousColumnsWidth}
              showSeasonColumn={showSeasonColumn}
              evaluateLastCategoryColumn={evaluateLastCategoryColumn}
              monthColumns={monthColumns}
              monthHeaderCellHeight={monthHeaderCellHeight}
              sortRowsBy={sortRowsBy}
              setSortRowsBy={setSortRowsBy}
              defineHierarchyToDisplay={defineHierarchyToDisplay}
              setHierarchyCellHeight={setHierarchyCellHeight}
            />
          </S.TableHead>
          <S.TableBody>
            <AdjustPlanSubtotalRow
              freezeColumns={freezeColumns}
              selectedProductIds={selectedProductIds}
              defineHierarchyToDisplay={defineHierarchyToDisplay}
              monthColumns={monthColumns}
              sumPreviousColumnsWidth={sumPreviousColumnsWidth}
              evaluateLastCategoryColumn={evaluateLastCategoryColumn}
              headerHeight={headerHeight}
              unit={unit}
              defineLeftCellBorder={defineLeftCellBorder}
              predictionData={predictions}
            />
            {selectedProductIds.map(productId => (
              <AdjustPlanProductRow
                freezeColumns={freezeColumns}
                productId={productId}
                sumPreviousColumnsWidth={sumPreviousColumnsWidth}
                setHierarchyColumnsWidth={setHierarchyColumnsWidth}
                showSeasonColumn={showSeasonColumn}
                evaluateLastCategoryColumn={evaluateLastCategoryColumn}
                defineHierarchyToDisplay={defineHierarchyToDisplay}
                selectedHierarchyIndexes={selectedHierarchyIndexes}
                selectedProductIds={selectedProductIds}
                monthColumns={monthColumns}
                showInputs={showInputs}
                inputs={inputs}
                setInputs={setInputs}
                saveLoading={saveLoading}
                setShowInputs={setShowInputs}
                predictionData={predictions}
                defineLeftCellBorder={defineLeftCellBorder}
                setFirstColumnWidth={setFirstColumnWidth}
                key={productId}
              />
            ))}
          </S.TableBody>
        </table>
      </TableControls>
    </TableWrapper>
  );
}
