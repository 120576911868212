import styled from "styled-components";

const Filters = styled.div`
  display: flex;
  gap: 50px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  .button-primary-medium {
    box-shadow: unset;
  }

  .button-primary-medium:hover {
    box-shadow: unset !important;
  }

  button.button.button-sm span.label {
    font-size: 12px !important;
  }
`;

const FirstSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 2rem 2rem 2rem;
`;

const DateContainer = styled.div`
  max-width: 220px;
  div.textfield-outer div.ragnarok.textfield-wrapper {
    font-size: 14px;
    border: ${({ border }) => (border ? "2px #c9a91ee6 solid" : "none")};
  }
`;

const Index = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SecondSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 60px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  th {
    border-bottom: solid 1px grey;
  }

  td {
    vertical-align: middle;
    border-bottom: solid 1px #dcdcdc;
  }

  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: #a7a7a7 !important;
  }
`;

const CenteredCell = styled.th`
  text-align: center;
  vertical-align: middle;
`;

const SwitchContainer = styled.div`
  align-self: center;
  margin: 10px 0 20px 0;

  .switch-outer {
    padding: 0;
  }

  .switch-outer .switch-label {
    font-size: 14px;
  }
`;

const LineContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Line = styled.div`
  border: solid grey 0.5px;
  width: 80%;
  margin: 2rem;
`;

const ChartContainer = styled.div`
  width: 500px;
`;

export {
  FirstSection,
  Filters,
  SelectContainer,
  FilterTitle,
  ButtonContainer,
  DateContainer,
  Index,
  SecondSection,
  ContentContainer,
  SwitchContainer,
  CenteredCell,
  ChartContainer,
  LineContainer,
  Line,
};
