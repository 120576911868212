import styled from "styled-components";

const ButtonCell = styled.td`
  position: ${({ freezeColumns }) => (freezeColumns ? "sticky" : "")};
  left: ${({ freezeColumns }) => (freezeColumns ? "0" : "")};
  background-color: white !important;
  vertical-align: middle;
  z-index: 4;

  button.button.button-sm.icon.no-label {
    width: 20px;
    height: 24px !important;
    min-height: unset;
  }
`;

export { ButtonCell };
