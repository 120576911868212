import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getOpportunities } from "../../services/OpportunityService";
import { getForecasts } from "../../services/ForecastService";
import { createUserEvent } from "../../services/UserEventsService";

import * as S from "./Opportunity.style";

import Default from "../../components/Default/Default";
import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";
import OpportunityOptions from "./OpportunityOptions/OpportunityOptions";
import ProductListItems from "./ProductListItems/ProductListItems";
import OpportunityCounter from "./OpportunityCounter/OpportunityCounter";
import RemovedButton from "./RemovedButton/RemovedButton";
import ContentLoader from "../../components/ContentLoader/ContentLoader";
import ResultError from "../../components/ResultError/ResultError";

export default function Opportunity() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const urlProductId = params.get("productId");

  const [forecast, setForecast] = useState({});
  const [opportunities, setOpportunities] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [types, setTypes] = useState([]);
  const [order, setOrder] = useState("descending");
  const [error, setError] = useState(false);

  const token = localStorage.getItem("token");

  const allTypeOptions = [
    {
      value: "additionalSale",
      displayName: "Venda Adicional",
    },
    {
      value: "avoidLoss",
      displayName: "Evitar Perda de Venda",
    },
    {
      value: "stock",
      displayName: "Otimizar a Compra",
    },
  ];

  const typeOptions = allTypeOptions.filter(type => types.includes(type.value));

  const status = ["active", "reactivated", "checkout"];

  const activeOpportunities = opportunities.filter(
    opportunity => opportunity.status === "active" || opportunity.status === "reactive"
  );

  useEffect(() => {
    fetchForecast();
    createUserEvent(token, { event: "Página Oportunidades", page: "Oportunidades" });
  }, []);

  useEffect(() => {
    fetchFiltersData();
  }, [forecast]);

  useEffect(() => {
    fetchOpportunities();
  }, [selectedProductIds, selectedTypes, order]);

  async function fetchForecast() {
    const latestForecast = await getForecasts(token, "opportunities");
    setForecast(latestForecast[0]);
  }

  async function fetchFiltersData() {
    if (forecast.forecastId) {
      const result = await getOpportunities(token, forecast.forecastId, selectedProductIds, order, selectedTypes, status);

      if (urlProductId) {
        setSelectedProductIds(urlProductId.split(","));
      } else {
        setSelectedProductIds(result.productIds);
      }

      setProductIds(result.productIds);

      const secondResult = await getOpportunities(token, forecast.forecastId, result.productIds, order, selectedTypes, status);
      setTypes(secondResult.types);
      setSelectedTypes(secondResult.types);
    }
  }

  async function fetchOpportunities() {
    if (forecast.forecastId) {
      const result = await getOpportunities(token, forecast.forecastId, selectedProductIds, order, selectedTypes, status);

      if (!result.opportunities.length) {
        setError(true);
      } else {
        setError(false);
      }

      setOpportunities(result.opportunities);
    }
  }

  return (
    <Default>
      <S.HeaderContent>
        <PageHeading>Oportunidades de Produto</PageHeading>
        <OpportunityCounter forecast={forecast} opportunities={opportunities} />
      </S.HeaderContent>

      <OpportunityOptions
        productIds={productIds}
        selectedProductIds={selectedProductIds}
        setSelectedProductIds={setSelectedProductIds}
        order={order}
        setOrder={setOrder}
        selectedTypes={selectedTypes}
        setSelectedTypes={setSelectedTypes}
        typeOptions={typeOptions}
      />

      <S.RemovedButtonContainer>
        <RemovedButton fetchOpportunitiesPageData={fetchFiltersData} forecastId={forecast.forecastId} key={opportunities} />
      </S.RemovedButtonContainer>

      <ContentLoader resultCondition={!error} contentCondition={opportunities.length} renderError={() => <ResultError />}>
        <ProductListItems opportunities={activeOpportunities} fetchData={fetchFiltersData} accordionButton />
      </ContentLoader>
    </Default>
  );
}
