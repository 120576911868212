import React from "react";
import { Text } from "@aidron/aidron-ds";
import { adjustPlanColumns } from "../../../../../../utils/tableColumns";
import { getAllPredictionsFromMonth } from "../../../../../../utils/utils";

import * as S from "./AdjustPlanSubtotalRow.style";

import Cell from "../Cell/Cell";

export default function AdjustPlanSubtotalRow({
  freezeColumns,
  selectedProductIds,
  defineHierarchyToDisplay,
  monthColumns,
  sumPreviousColumnsWidth,
  evaluateLastCategoryColumn,
  headerHeight,
  unit,
  defineLeftCellBorder,
  predictionData,
}) {
  function calculatePrediction(month) {
    const allPredictionsFromMonth = getAllPredictionsFromMonth(month, predictionData);
    const monthProductPredictions = allPredictionsFromMonth.filter(prediction =>
      selectedProductIds.includes(prediction.productId)
    );

    const result = {};

    adjustPlanColumns.forEach(column => {
      const values = { allPredictions: allPredictionsFromMonth, selectedPredictions: monthProductPredictions, ...column };

      result[column.name] = column.calculate(values);
    });
    return result;
  }

  function defineRightBorderFade() {
    if (defineHierarchyToDisplay().length === 0 && freezeColumns) {
      return "right-border-fade-with-background-color";
    }
    return "";
  }

  return selectedProductIds.length > 0 ? (
    <S.Row className="font-weight-bold-on-paragraph">
      <td
        className={`background-color-white z-5 cell-bottom-border position-sticky start-0 ${defineRightBorderFade()}`}
        style={{
          left: freezeColumns ? `${sumPreviousColumnsWidth(0)}px` : "",
          top: `${headerHeight}px`,
        }}
      />
      {defineHierarchyToDisplay().length !== 0 ? (
        <td
          colSpan={defineHierarchyToDisplay().length}
          className={`background-color-white z-2 cell-bottom-border position-sticky 
          ${evaluateLastCategoryColumn(defineHierarchyToDisplay().length - 1)} ${freezeColumns ? "" : "cell-right-border"}
          `}
          style={{
            left: freezeColumns ? `${sumPreviousColumnsWidth(0)}px` : "",
            top: `${headerHeight}px`,
          }}
        >
          <Text className="font-size-17px">Total</Text>
        </td>
      ) : (
        <></>
      )}
      {monthColumns.map((month, index) =>
        adjustPlanColumns.map((column, i) => (
          <td
            className={`text-center background-color-white cell-top-border position-sticky cell-bottom-border z-1 ${
              defineLeftCellBorder(index, i) ? "cell-left-border" : ""
            }`}
            style={{ top: `${headerHeight}px` }}
            key={i}
          >
            <Cell
              prediction={calculatePrediction(month)?.[column.name]}
              format={unit}
              color={column.color}
              adjustmentColumn={column.name === "opportunityAdjustment"}
            />
          </td>
        ))
      )}
    </S.Row>
  ) : (
    <></>
  );
}
