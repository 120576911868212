import React from "react";
import { Select, SelectItem } from "@aidron/aidron-ds";
import { defineGroupFilterOptions } from "../../utils/utils";

import * as S from "./HierarchyGroupFilter.style";

import CustomText from "../StyledComponents/CustomText/CustomText.style";

export default function HierarchyGroupFilter({ label, products, selected, onChange, direction }) {
  function handleChange(value) {
    if (value) onChange(value);
  }
  return (
    <S.Wrapper direction={direction}>
      <CustomText weight="bold" margin={direction === "column" ? "" : "0 1rem 0 0"}>
        {label}
      </CustomText>
      <Select readOnly label="" selected={selected} handleChange={handleChange}>
        {defineGroupFilterOptions(products).map(option => (
          <SelectItem value={option.value} key={option.value}>
            {option.displayName}
          </SelectItem>
        ))}
      </Select>
    </S.Wrapper>
  );
}
