import React, { useContext, useEffect, useState, useRef } from "react";
import { Prompt } from "react-router-dom/cjs/react-router-dom";
import { getPredictions, getProductIds } from "../../services/PredictionsService";
import { ForecastContext } from "../../templates/context/ForecastContext";
import { CompanyContext } from "../../templates/context/CompanyContext";
import { defineMonthColumns, defineGroupFilterOptions } from "../../utils/utils";

import Default from "../../components/Default/Default";
import AdjustPlanTable from "./AdjustPlanTable/AdjustPlanTable";
import AdjustPlanTableOptions from "./AdjustPlanTableOptions/AdjustPlanTableOptions";
import AdjustHeader from "./AdjustHeader/AdjustHeader";
import MainIndexes from "./AdjustSummary/MainIndexes/MainIndexes";

export default function AdjustPlan() {
  const { hierarchy, userInfo, allProducts } = useContext(CompanyContext);
  const { selectedForecast } = useContext(ForecastContext);

  const initialSortBy = {
    orderForecastId: selectedForecast.forecastId,
    columnLabel: "realized",
    order: "descending",
    months: [selectedForecast.month],
    year: selectedForecast.year,
  };

  const [predictions, setPredictions] = useState([]);
  const [filteredPredictions, setFilteredPredictions] = useState([]);
  const [originalMonthColumns, setOriginalMonthColumns] = useState([]);
  const [sortRowsBy, setSortRowsBy] = useState(initialSortBy);
  const [selectedHierarchyIndexes, setSelectedHierarchyIndexes] = useState(hierarchy.levels.map((level, index) => index));
  const [productIds, setProductIds] = useState([]);
  const [pageProductIds, setPageProductIds] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [freezeColumns, setFreezeColumns] = useState(true);
  const [selectedLevels, setSelectedLevels] = useState({});
  const [inputtedExpressions, setInputtedExpressions] = useState([]);
  const [selectedMonthsValue, setSelectedMonthsValue] = useState();
  const [selectedGroupValue, setSelectedGroupValue] = useState(1);
  const [inputs, setInputs] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const productViewRef = useRef(null);

  const products = allProducts.filter(product => productIds.includes(product.productId));
  const selectedGroup = defineGroupFilterOptions(products).find(option => option.value === selectedGroupValue);

  const unit = userInfo.company.unit;
  const token = localStorage.getItem("token");

  const monthColumns = defineMonthColumns(filteredPredictions);

  const selectedMonths = originalMonthColumns.filter(column => selectedMonthsValue.includes(column.id));

  useEffect(() => {
    fetchPredictions();
  }, []);

  useEffect(() => {
    fetchProductIds();
  }, [sortRowsBy]);

  async function fetchPredictions() {
    const result = await getPredictions(token, [selectedForecast.forecastId]);
    const allColumns = defineMonthColumns(result.predictions);
    const columnsIds = allColumns.map(column => column.id);
    setSelectedMonthsValue(columnsIds);

    const initialMonthColumns = allColumns.slice(1);
    setOriginalMonthColumns(initialMonthColumns);

    const initialMonthsArray = initialMonthColumns.map(month => month.month.toString() + month.year.toString());
    const predictionsFromInitialMonths = result.predictions.filter(prediction => {
      const predictionDate = prediction.month.toString() + prediction.year.toString();
      return initialMonthsArray.includes(predictionDate);
    });

    setPredictions(predictionsFromInitialMonths);

    setFilteredPredictions(predictionsFromInitialMonths);
  }

  async function fetchProductIds() {
    // dps ver se nao deveria ser uma funcao que só traz os productIds que tem adjustment
    const result = await getProductIds(token, selectedForecast.forecastId, sortRowsBy);
    setProductIds(result.productIds);
    setSelectedProductIds(result.productIds);
  }

  useEffect(() => {
    const selectedMonthsArray = selectedMonths.map(month => month.month.toString() + month.year.toString());
    const newFilteredPredictions = predictions.filter(prediction => {
      const predictionDate = prediction.month.toString() + prediction.year.toString();
      return selectedGroup.productIds.includes(prediction.productId) && selectedMonthsArray.includes(predictionDate);
    });
    setFilteredPredictions(newFilteredPredictions);

    const newFilteredProductIds = productIds.filter(productId => selectedGroup.productIds.includes(productId));
    setPageProductIds(newFilteredProductIds);
    setSelectedProductIds(newFilteredProductIds);
  }, [selectedMonthsValue, selectedGroupValue]);

  useEffect(() => {
    setSelectedLevels({});
    setInputtedExpressions([]);
  }, [selectedGroupValue]);

  function defineProductFilter(filterBy) {
    scrollToProductView();
    setInputtedExpressions([]);
    // setSelectedProductIds(productIds);
    setSelectedLevels(filterBy);
  }

  function scrollToProductView() {
    if (productViewRef.current) {
      productViewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  function formatIndex(number) {
    const prefix = unit === "currency" ? "R$ " : "";
    return `${number < 0 ? "-" : ""} ${prefix} ${Math.ceil(Math.abs(number)).toLocaleString("pt-BR")}`;
  }

  return (
    <Default>
      <Prompt
        when={showInputs ? true : false}
        message="Pode haver alterações não salvas na edição do Novo Plano. Tem certeza que deseja sair?"
      />

      <AdjustHeader
        products={products}
        selectedGroup={selectedGroup}
        setSelectedGroupValue={setSelectedGroupValue}
        filteredPredictions={filteredPredictions}
        originalMonthColumns={originalMonthColumns}
        selectedMonthsValue={selectedMonthsValue}
        setSelectedMonthsValue={setSelectedMonthsValue}
        formatIndex={formatIndex}
      />

      <MainIndexes
        predictions={filteredPredictions}
        products={products}
        selectedGroup={selectedGroup}
        defineProductFilter={defineProductFilter}
        selectedLevels={selectedLevels}
        formatIndex={formatIndex}
      />

      <div ref={productViewRef}>
        <AdjustPlanTableOptions
          selectedHierarchyIndexes={selectedHierarchyIndexes}
          setSelectedHierarchyIndexes={setSelectedHierarchyIndexes}
          freezeColumns={freezeColumns}
          setFreezeColumns={setFreezeColumns}
          productIds={pageProductIds}
          selectedProductIds={selectedProductIds}
          setSelectedProductIds={setSelectedProductIds}
          selectedLevels={selectedLevels}
          setSelectedLevels={setSelectedLevels}
          inputtedExpressions={inputtedExpressions}
          setInputtedExpressions={setInputtedExpressions}
          showInputs={showInputs}
          setShowInputs={setShowInputs}
          inputs={inputs}
          fetchPredictions={fetchPredictions}
          saveLoading={saveLoading}
          setSaveLoading={setSaveLoading}
          predictions={predictions}
          setInputs={setInputs}
          filteredPredictions={filteredPredictions}
        />
        <AdjustPlanTable
          selectedProductIds={selectedProductIds}
          predictions={filteredPredictions}
          freezeColumns={freezeColumns}
          monthColumns={monthColumns}
          selectedHierarchyIndexes={selectedHierarchyIndexes}
          sortRowsBy={sortRowsBy}
          setSortRowsBy={setSortRowsBy}
          unit={unit}
          showInputs={showInputs}
          inputs={inputs}
          setInputs={setInputs}
          saveLoading={saveLoading}
          setShowInputs={setShowInputs}
        />
      </div>
    </Default>
  );
}
