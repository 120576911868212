import styled from "styled-components";

const OuterContainer = styled.div`
  display: grid;
  grid-template-columns: 100px 10fr;
  margin: 0.7rem 3rem;

  .react-multi-carousel-dot button {
    background-color: #ffffff;
  }
`;

const HeadingBox = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 40%;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 10px 0;
  z-index: 2;
  width: max-content;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;

  .button.button.button-sm {
    height: 25px !important;
    min-height: 25px !important;

    span {
      font-size: 13px !important;
    }
  }
`;

export { OuterContainer, HeadingBox, CardsContainer, Box, ButtonContainer };
