import styled from "styled-components";

const TableRow = styled.tr`
  --bs-table-accent-bg: white !important;
  &:hover {
    --bs-table-accent-bg: unset !important;
  }
`;

const Container = styled.div`
  width: 85vw;
  position: sticky;
  left: 9px;
  max-height: 55vh;
`;

const Column = styled.div`
  display: flex;
  justify-content: center;
`;

const InnerChartContainer = styled.div`
  width: 400px;
`;

const ChartsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr 0.3fr;
  margin-top: 5px;
`;

const Indexes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

const Index = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  p {
    white-space: break-spaces;
    line-height: 1.3;
  }
`;

export { Column, TableRow, Container, InnerChartContainer, ChartsRow, Indexes, Index };
