import styled from "styled-components";

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  display: inline-block;
  text-align: center;
  cursor: default !important;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: #f26f21;
  margin-left: 5px;
  vertical-align: text-top;

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1px;
  }

  &:hover {
    .custom-tooltip {
      display: flex !important;
    }
  }
`;

const TooltipBox = styled.div`
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  top: 10px;
  transform: translateX(-55%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  opacity: 0.9;
  white-space: break-spaces;
  width: 500px;
  text-align: center;
  z-index: 100;
  display: none;
`;

const OuterChartContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const InnerChartContainer = styled.div`
  max-width: 600px;
`;

export { TitleContainer, Button, TooltipBox, Tooltip, OuterChartContainer, InnerChartContainer };
