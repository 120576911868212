import React, { useState, useEffect } from "react";
import { Link, Switch, Route, useLocation } from "react-router-dom";
import { Button } from "@aidron/aidron-ds";
import { getUserEvents } from "../../services/UserEventsService";

import Default from "../../components/Default/Default";
import PageHeading from "../../components/StyledComponents/PageHeading/PageHeading.style";
import ProductTable from "./ProductTable/ProductTable";
import GeneralView from "./GeneralView/GeneralView";

export default function UserActivity() {
  const [allUserEvents, setAllUserEvents] = useState([]);

  const location = useLocation();
  const tabs = [
    { name: "Visão geral", route: "visaoGeral" },
    { name: "Produtos", route: "produtos" },
  ];

  function defineTabButtonStyle(route) {
    return location.pathname === `/atividadeDeUsuarios/${route}` ? "filled" : "outlined";
  }

  function defineBorderStyle(route) {
    return location.pathname === `/atividadeDeUsuarios/${route}` ? "" : "border-bottom-0";
  }

  function getCompanies() {
    const companies = allUserEvents.map(event => event.user.company.name);
    return [...new Set(companies)];
  }

  useEffect(() => {
    fetchUserEvents();
  }, []);

  async function fetchUserEvents() {
    const token = localStorage.getItem("token");
    const fetchedEvents = await getUserEvents(token);
    setAllUserEvents(fetchedEvents);
  }

  return (
    <Default>
      <PageHeading>Atividade de Usuários</PageHeading>

      <div className="border-bottom-orange d-flex mt-4">
        {tabs.map((tab, index) => (
          <Link to={`/atividadeDeUsuarios/${tab.route}`} key={index}>
            <Button
              label={tab.name}
              onClick={() => {}}
              type={defineTabButtonStyle(tab.route)}
              className={`border-radius-15px-150px-0-0 box-shadow-black-2--2-5-0 box-shadow-black-1--3-6-on-button-hover border-bottom-0 ms-3 ${defineBorderStyle(
                tab.route
              )}`}
            />
          </Link>
        ))}
      </div>
      <Switch>
        <Route path="/atividadeDeUsuarios/visaoGeral">
          <GeneralView allUserEvents={allUserEvents} getCompanies={getCompanies} />
        </Route>
        <Route path="/atividadeDeUsuarios/produtos">
          <ProductTable getCompanies={getCompanies} />
        </Route>
      </Switch>
    </Default>
  );
}
