import React from "react";

import * as S from "./SectionHeader.style";

import CustomHeading from "../StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../StyledComponents/CustomText/CustomText.style";

export default function SectionHeader({ heading, subtitles, headingSize, pageHeading }) {
  return (
    <S.OuterContainer pageHeading={pageHeading}>
      <CustomHeading size={headingSize ? headingSize : "md"} margin="0">
        {heading}
      </CustomHeading>
      {subtitles?.length ? (
        subtitles.map((subtitle, index) => (
          <CustomText margin="0 0" size="xs" key={index}>
            {subtitle}
          </CustomText>
        ))
      ) : (
        <></>
      )}
    </S.OuterContainer>
  );
}
