import React, { useContext, useEffect, useRef } from "react";
import { Text } from "@aidron/aidron-ds";
import { getMonthName } from "../../utils/utils";
import { accuracyColumnsCurrentMonth } from "../../utils/tableColumns";
import { CompanyContext } from "../../templates/context/CompanyContext";

export default function MonthHeaderCells({
  monthColumns,
  monthHeaderCellHeight,
  setMonthHeaderCellHeight,
  setMonthColumnsWidth,
  wape,
  monthHeaderCellsSpan,
  defineLeftCellBorder,
  currentMonth,
  historicMonthsToDisplay,
  renderColumnTitleLabel,
  thirdSectionColumns,
  renderThirdColumnTitleLabel,
  thirdSectionCellsSpan,
}) {
  const { userInfo } = useContext(CompanyContext);
  const monthRefs = useRef(null);
  const currentMonthRef = useRef(null);

  function defineMonthColumnRefs(node, index) {
    const map = getMonthRefs();
    if (node) {
      map.set(index, node);
    } else {
      map.delete(index);
    }
  }

  function getMonthRefs() {
    if (!monthRefs.current) {
      monthRefs.current = new Map();
    }
    return monthRefs.current;
  }

  useEffect(() => {
    if (monthColumns.length) {
      const firstMonthValue = monthRefs.current.get(0);
      setMonthHeaderCellHeight(firstMonthValue.getBoundingClientRect().height);
    }
  }, [monthRefs.current, monthColumns, currentMonth, historicMonthsToDisplay]);

  useEffect(() => {
    if (monthRefs.current) {
      const columnsWidth = [];

      for (let value of monthRefs.current.values()) {
        columnsWidth.push(value.getBoundingClientRect().width);
      }
      if (currentMonthRef?.current) {
        columnsWidth.push(currentMonthRef.current.getBoundingClientRect().width);
      }
      setMonthColumnsWidth(columnsWidth);
    }
  }, [monthHeaderCellHeight]);

  function getWapeValues(month) {
    return wape ? wape.find(w => w.month === month.month && w.year === month.year) : "";
  }

  return (
    <>
      {monthColumns.map((month, index) => (
        <th
          colSpan={monthHeaderCellsSpan}
          className={`text-center vertical-align-baseline month-cells ${
            defineLeftCellBorder(index, 0) ? "cell-left-border" : ""
          } pb-0`}
          ref={node => defineMonthColumnRefs(node, index)}
          key={index}
        >
          {renderColumnTitleLabel({ month })}
          {wape && getWapeValues(month) ? (
            <Text className="font-size-16px fw-normal">
              WAPE: {getWapeValues(month).aidron ? `IA ${getWapeValues(month).aidron.toLocaleString("pt-BR")}%` : ""}{" "}
              {getWapeValues(month).client
                ? `| ${userInfo.company.name} ${getWapeValues(month).client.toLocaleString("pt-BR")}%`
                : ""}
            </Text>
          ) : (
            <></>
          )}
        </th>
      ))}
      {thirdSectionColumns && thirdSectionColumns.length !== 0 ? (
        thirdSectionColumns.map((column, index) => (
          <th
            colSpan={thirdSectionCellsSpan ? thirdSectionCellsSpan : 1}
            className={`text-center vertical-align-baseline ${defineLeftCellBorder(index, 0) ? "cell-left-border" : ""} pb-0`}
            key={index}
          >
            {renderThirdColumnTitleLabel({ column })}
          </th>
        ))
      ) : (
        <></>
      )}
      {currentMonth ? (
        <th
          colSpan={accuracyColumnsCurrentMonth({ companyName: userInfo.company.name }).length}
          className={`text-center ${monthColumns.length ? "cell-left-border" : ""}`}
          ref={currentMonthRef}
        >
          <Text className="font-size-16px">
            {getMonthName(currentMonth.month.toString())} {currentMonth.year}
          </Text>
        </th>
      ) : (
        <></>
      )}
    </>
  );
}
