import React from "react";

import ButtonWithModal from "../../../../../components/ButtonWithModal/ButtonWithModal";
import ProductDriversChart from "./ProductDriversChart/ProductDriversChart";
import ProductDriversData from "../../../../../components/ProductDriversData/ProductDriversData";

export default function ProductDriversButton({ productId }) {
  return (
    <ButtonWithModal
      label="Impacto na Venda"
      icon="arrow-up-right"
      type="outlined"
      modalHeading=" "
      modalWidth="700px"
      renderModalContent={() => (
        <ProductDriversData productId={productId}>
          <ProductDriversChart />
        </ProductDriversData>
      )}
    />
  );
}
