import React, { memo, useEffect, useState, useRef } from "react";
import { Card, Radio, RadioOption } from "@aidron/aidron-ds";
import TableScrollButtons from "../TableScrollButtons/TableScrollButtons";
import TableFades from "../TableFades/TableFades";

function TableControls({
  children,
  freezeColumns,
  columns,
  columnsWidth,
  columnsPosition,
  selectedUnit,
  setSelectedUnit,
  unitOptions,
  initialScrollIndex,
  hideControls,
}) {
  const scrollingDiv = useRef(null);
  const nonScrollingDiv = useRef(null);
  const maxScrollPosition = scrollingDiv.current?.scrollWidth - nonScrollingDiv.current?.offsetWidth;
  const [scrollingDivPosition, setScrollingDivPosition] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);

  // useEffect(() => {
  //   const currentMonthPosition = columnsPosition[currentMonthIndex()];
  //   const hierarchyColumnsWidthSum = columnsPosition[0];
  //   // const twoThirdsOfNonScrollingDivWidth =
  //   //   (nonScrollingDiv.current.offsetWidth / 3) * 2;

  //   // if (hierarchyColumnsWidthSum > twoThirdsOfNonScrollingDivWidth) {
  //   //   setFreezeColumns(false);
  //   // } else {
  //   scrollTo(currentMonthPosition - hierarchyColumnsWidthSum);
  //   // }
  // }, [columnsPosition]);

  useEffect(() => {
    scrollTo(initialScrollIndex);
  }, [isFirstRender]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  function currentMonthIndex() {
    return columns.findIndex(month => month.month === month.currentMonth);
  }

  function scrollTo(point) {
    scrollingDiv.current.scrollTo({ top: 0, left: point, behavior: "smooth" });
  }

  function updateScrollingDivPosition() {
    const newScrollPosition = scrollingDiv.current.scrollLeft;
    setScrollingDivPosition(newScrollPosition);
  }

  function scrollToSide(buttonClicked) {
    const leftOrRight = buttonClicked === "left" ? -300 : 300;
    // let desiredMonthPosition =
    //   columnsPosition[centralizedMonthIndex() + leftOrRight];
    let desiredMonthPosition = scrollingDiv.current.scrollLeft + leftOrRight;

    // if (freezeColumns) {
    //   const hierarchyColumnsWidthSum = monthColumnsWidth[0];
    //   desiredMonthPosition -= hierarchyColumnsWidthSum;
    // } else desiredMonthPosition -= 20;

    scrollTo(desiredMonthPosition);
  }

  function centralizedMonthIndex() {
    const hierarchyColumnsWidthSum = columnsPosition[0];
    const leftEnd = freezeColumns ? scrollingDivPosition + hierarchyColumnsWidthSum : scrollingDivPosition;

    return columnsPosition.findIndex((monthPosition, index) => leftEnd <= monthPosition + columnsWidth[index] / 3);
  }

  function isScrollingDivAtBeginning() {
    return scrollingDivPosition >= 1;
  }

  function isScrollingDivAtEnd() {
    return scrollingDivPosition >= maxScrollPosition;
  }

  return (
    <Card style={{ padding: "1rem" }}>
      {selectedUnit && (
        <div className="d-flex justify-content-end">
          <div className="me-3 mb-2">
            <Radio label="" selected={selectedUnit} handleChange={setSelectedUnit} className="d-flex">
              {unitOptions.map((option, index) => (
                <RadioOption value={option.value} key={index}>
                  {option.displayName}
                </RadioOption>
              ))}
            </Radio>
          </div>
        </div>
      )}
      {!hideControls && (
        <TableScrollButtons
          scrollToSide={scrollToSide}
          isScrollingDivAtBeginning={isScrollingDivAtBeginning}
          isScrollingDivAtEnd={isScrollingDivAtEnd}
        />
      )}
      <div className="position-relative" ref={nonScrollingDiv}>
        <TableFades
          freezeColumns={freezeColumns}
          isScrollingDivAtBeginning={isScrollingDivAtBeginning}
          isScrollingDivAtEnd={isScrollingDivAtEnd}
        />
        <div
          className="table-inner-div position-relative overflow-scroll"
          ref={scrollingDiv}
          onScroll={updateScrollingDivPosition}
        >
          {children}
        </div>
      </div>
    </Card>
  );
}

export default memo(TableControls);
