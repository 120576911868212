import styled from "styled-components";

export const TableHead = styled.thead`
  .first-row {
    padding: 0 !important;
  }

  .month-cells {
    padding: 0 !important;
  }

  .column-label-cell {
    padding: 9.6px 8.5px !important;
  }
`;

export const TableBody = styled.tbody`
  td {
    padding: 9px !important;
  }
`;
