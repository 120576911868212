import React from "react";
import CustomText from "../../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function Cell({ prediction, format, color, adjustmentColumn }) {
  const prefix = format === "items" ? "" : "R$ ";
  return (
    <CustomText className={format === "items" ? "text-center" : ""} color={color(prediction)} weight="bold">
      {!isNaN(prediction) && prediction !== null
        ? `${prediction < 0 ? "-" : adjustmentColumn ? "+" : ""} ${prefix} ${Math.ceil(Math.abs(prediction)).toLocaleString(
            "pt-BR"
          )}`
        : " "}
    </CustomText>
  );
}
