import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr;
  gap: 20px;
  align-items: center;
`;

const TextContainer = styled.div`
  width: 200px;

  p {
    white-space: break-spaces;
  }
`;

const OuterBar = styled.div`
  width: 200px;
  background-color: #fafafa;
  height: 15px;
`;

const InnerBar = styled.div`
  background-color: #f26f21;
  width: ${({ width }) => (width ? `${width}%` : "100%")};
  height: 100%;
  border-radius: 0 20px 20px 0;
`;

export { Grid, TextContainer, OuterBar, InnerBar };
