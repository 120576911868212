import styled from "styled-components";

const OuterContainer = styled.div`
  margin-bottom: 10px;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const AnalysisButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowButtonContainer = styled.div`
  margin-right: 10px;
`;

export { OuterContainer, InnerContainer, ButtonsContainer, AnalysisButtonContainer, ArrowButtonContainer };
