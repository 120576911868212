import React from "react";
import { createUserEvent } from "../../../../services/UserEventsService";

import * as S from "./InnerCheckoutCard.style";

import CustomCard from "../../../../components/StyledComponents/CustomCard/CustomCard.style";
import CustomText from "../../../../components/StyledComponents/CustomText/CustomText.style";

export default function InnerCheckoutCard({ heading, number, userEventName }) {
  function isValue() {
    return /\d/.test(number);
  }

  function opportunity() {
    return number === 1 ? "oportunidade" : "oportunidades";
  }

  function hasOpportunity() {
    return number > 0;
  }

  function onLinkClick() {
    const token = localStorage.getItem("token");
    createUserEvent(token, { page: "Home", event: `Botão Home Checkout - ${userEventName}` });
  }

  return (
    <S.Wrapper>
      <CustomCard padding="8px 13px">
        <S.InnerContainer>
          <CustomText weight="bold" color="mediumGrey">
            {heading}
          </CustomText>
          {isValue() ? (
            hasOpportunity() ? (
              <S.StyledLink to="/acoes/checkout" onClick={onLinkClick}>
                <CustomText color="orange">{`${number} ${opportunity()} em Checkout`}</CustomText>
                <S.ChevronIcon icon="chevron-right" size="17" />
              </S.StyledLink>
            ) : (
              <CustomText color="orange">{`${number} ${opportunity()} em Checkout`}</CustomText>
            )
          ) : (
            <CustomText color="orange" weight="bold">
              {number}
            </CustomText>
          )}
        </S.InnerContainer>
      </CustomCard>
    </S.Wrapper>
  );
}
