import React, { useState, useEffect } from "react";
import { getForecasts } from "../../services/ForecastService";
import { getProductDrivers } from "../../services/ProductDriver";

export default function ProductDriversData({ productId, children }) {
  const [productDrivers, setProductDrivers] = useState([]);
  const [clientFlow, setClientFlow] = useState("");

  const orderedProductDrivers = productDrivers?.sort((a, b) => b.value - a.value) || [];

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const token = localStorage.getItem("token");

    const forecasts = await getForecasts(token, "productDrivers");

    const latestForecastId = forecasts[forecasts.length - 1].forecastId;

    const drivers = await getProductDrivers(token, latestForecastId, [productId]);

    const filteredDrivers = drivers.filter(driver => driver.driver !== "Fluxo de Clientes (Índices do Varejo no Brasil)");

    const result = filteredDrivers.length ? filteredDrivers : undefined;
    setProductDrivers(result);

    const clientFlowValue = drivers.find(driver => driver.driver === "Fluxo de Clientes (Índices do Varejo no Brasil)")?.value;
    setClientFlow(clientFlowValue);
  }

  return orderedProductDrivers.length ? (
    React.Children.map(children, child =>
      React.isValidElement(child)
        ? React.cloneElement(child, { productId, productDrivers, clientFlow, orderedProductDrivers })
        : child
    )
  ) : (
    <></>
  );
}
