import React from "react";
import { Icon } from "@aidron/aidron-ds";

import * as S from "./SumCard.style";

import CustomCard from "../../../../../components/StyledComponents/CustomCard/CustomCard.style";
import CustomHeading from "../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import CustomText from "../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function SumCard({ icon, number, numberColor, label, formatIndex, prefix }) {
  return (
    <CustomCard backgroundColor="lightGrey" minWidth="200px" padding="5px 10px">
      <S.OuterContainer>
        <Icon icon={icon} size={40} color="color-neutral-40" />
        <S.InnerContainer>
          <CustomHeading size="md" color={numberColor ? numberColor : ""} margin="0">
            {`${prefix ? prefix : ""} ${formatIndex(Math.abs(number))}`}
          </CustomHeading>
          <CustomText weight="bold">{label}</CustomText>
        </S.InnerContainer>
      </S.OuterContainer>
    </CustomCard>
  );
}
