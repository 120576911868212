import styled from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
`;

const Input = styled.input`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
  border: 0.0625rem solid rgb(17 24 39 / 35%);
  border-radius: 3px;
  width: ${({ width }) => width};
`;

const IconButton = styled.div`
  cursor: pointer;

  &:hover {
    .custom-tooltip {
      display: flex !important;
    }
  }
`;

const TooltipBox = styled.div`
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  top: -70px;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  opacity: 0.9;
  white-space: break-spaces;
  width: 100px;
  text-align: center;
  z-index: 100;
  display: none;
`;

export { OuterContainer, InputContainer, Input, TooltipBox, IconButton, Tooltip };
