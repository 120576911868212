import styled from "styled-components";

const OuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 30px;

  div.menu-select-wrapper div.textfield-outer div.textfield-wrapper {
    border: 0.0625rem solid rgba(17, 24, 39, 0.125);
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  gap: 30px;

  div.menu-select-wrapper div.textfield-outer div.textfield-wrapper {
    height: 20px !important;
    width: 200px !important;
  }

  div.textfield-outer div.ragnarok.textfield-wrapper button {
    margin-top: 12px !important;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const IndexesContainer = styled.div`
  display: flex;
  gap: 30px;
`;

const IndexBox = styled.div`
  display: flex;
  gap: 12px;
`;

export { OuterContainer, FiltersContainer, SelectWrapper, IndexesContainer, IndexBox };
