import React from "react";
import { Text } from "@aidron/aidron-ds";

import * as S from "./ProductDriversChart.style";

import ProductHierarchyHeading from "../../../../../../components/ProductHierarchyHeading/ProductHierarchyHeading";
import ChartSeries from "./ChartSeries/ChartSeries";
import ContentLoader from "../../../../../../components/ContentLoader/ContentLoader";
import CustomHeading from "../../../../../../components/StyledComponents/CustomHeading/CustomHeading.style";
import IconButtonWithModal from "../../../../../../components/IconButtonWithModal/IconButtonWithModal";
import ClientFlowSection from "../../../../../../components/ClientFlowSection/ClientFlowSection";

export default function ProductDriversChart({ productId, productDrivers, orderedProductDrivers, clientFlow }) {
  return (
    <ContentLoader
      resultCondition={productDrivers}
      contentCondition={productDrivers?.length}
      renderError={() => <Text>Esta funcionalidade estará disponivel em breve.</Text>}
    >
      <S.HeadingContainer>
        <ProductHierarchyHeading productId={productId} />
      </S.HeadingContainer>
      <S.TitleContainer>
        <CustomHeading size="sm">Fatores que impactam a venda deste produto</CustomHeading>
        <IconButtonWithModal
          icon="help-circle"
          modalHeading="Ajuda: Fatores que impactam a venda deste produto"
          renderModalContent={() => (
            <Text>
              Esta análise identifica % de impacto de cada fator na venda deste produto. Ela é importante para dignóstico e
              proposição de ações que possam otimizar estoque e/ou aumentar as vendas.
            </Text>
          )}
        />
      </S.TitleContainer>
      <S.OuterChartContainer>
        <S.InnerChartContainer>
          {orderedProductDrivers.map(driver => (
            <ChartSeries series={driver} key={driver.driver} />
          ))}
        </S.InnerChartContainer>
      </S.OuterChartContainer>

      <ClientFlowSection clientFlow={clientFlow} />
    </ContentLoader>
  );
}
