import styled from "styled-components";

const AIButtonContainer = styled.div`
  button.button.button-outlined.button-primary-medium,
  button.button.button-filled.button-primary-medium {
    padding: 5px 7.5px !important;
  }

  &:hover {
    .custom-tooltip {
      display: flex !important;
    }
  }
`;

const TooltipBox = styled.div`
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  top: -60px;
  transform: translateX(-55%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  opacity: 0.9;
  white-space: break-spaces;
  width: 100px;
  text-align: center;
  z-index: 100;
  display: none;
`;

export { AIButtonContainer, TooltipBox, Tooltip };
