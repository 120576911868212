import React from "react";

import * as S from "./HierarchySumBox.style";

import CustomCard from "../../../../../components/StyledComponents/CustomCard/CustomCard.style";
import CustomText from "../../../../../components/StyledComponents/CustomText/CustomText.style";

export default function HierarchySumBox({ group, filteredPredictions, formatIndex, boxCount }) {
  const filteredByGroup = filteredPredictions.filter(prediction => group.productIds.includes(prediction.productId));

  const clientPlanSum = filteredByGroup.reduce((acc, curr) => {
    if (curr.clientPrediction) return acc + Number(curr.clientPrediction);
    else return acc;
  }, 0);

  const newPlanSum = filteredByGroup.reduce((acc, curr) => {
    const property = curr.editedNewPlan ? "editedNewPlan" : "newPlan";
    if (curr[property]) return acc + Number(curr[property]);
    else return acc;
  }, 0);

  const difference = clientPlanSum - newPlanSum;

  return (
    <CustomCard padding="10px" width={boxCount < 3 ? "300px" : "225px"} margin="0px 15px">
      <S.OuterContainer>
        <CustomText allCaps color="mediumGrey" weight="bold" textAlign="center" size="16px" margin="0">
          {group.name}
        </CustomText>

        <S.SumLine>
          <CustomText color="mediumGrey" weight="bold">
            ATUAL
          </CustomText>
          <CustomText color="mediumGrey" weight="bold" size="16px">
            {formatIndex(clientPlanSum)}
          </CustomText>
        </S.SumLine>
        <S.SumLine>
          <CustomText color="mediumGrey" weight="bold">
            NOVO
          </CustomText>
          <CustomText color="orange" weight="bold" size="16px">
            {formatIndex(newPlanSum)}
          </CustomText>
        </S.SumLine>
        <S.Line />
        <S.SumLine>
          <CustomText color="mediumGrey" weight="bold">
            DIFERENÇA
          </CustomText>
          <CustomText color={difference > 0 ? "red" : "blue"} weight="bold" size="16px">
            {`${difference > 0 ? "-" : "+"} ${formatIndex(Math.abs(difference))}`}
          </CustomText>
        </S.SumLine>
      </S.OuterContainer>
    </CustomCard>
  );
}
