import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
p {
    margin-bottom: 3px;
    margin-top: 3px;
}

.navbar {
    height: 57px;
}

.text-body-sm-bold {
    font-size: 14px;
}

.text-heading-sm {
    font-size: 18px;
}

.text-heading-md {
    font-size: 20px;
}

.text-heading-lg {
    font-size: 25px;
}

.badge {
    height: fit-content;
}

.progress {
    box-shadow: unset !important;
}

/* Ajustes para todos os botões */
.button-primary-medium {
    padding: 6px 10px !important;
    width: fit-content;
    box-shadow: rgb(0 0 0 / 23%) 1.95px 1.95px 5px;
}

.button-primary-medium:disabled, .button-primary-medium:disabled:hover {
    box-shadow: rgb(0 0 0 / 10%) 1.95px 1.95px 5px !important;
}

.button-primary-medium:hover {
    box-shadow: rgb(0 0 0 / 30%) 1.95px 1.95px 8px !important;
}

button.button.button-sm span.label {
    font-size: 14px !important;
}

button.button.button-outlined.button-primary-medium:disabled {
    color: #e9e8e8 !important;
    border-color: #e9e8e8 !important;
} /* Ajuste na cor dos botões desabilitados que não estejam em fundo cinza */

button.button.button-lg.icon.no-label {
    width: 45px;
}

button.button.button-lg {
    min-height: 45px !important;
}

button.button.button-lg span.label {
    font-size: 14px;
}

.button-primary-medium {
    height: fit-content !important;
} /* Garantir que os botões de tamanho médio tenham altura suficiente */

button.button.button-sm {
    min-height: 32px;
}

.menu ul.dropdown .menu-item {
    font-size: 14px;
}

.text-body-sm-regular {
    font-size: 14px;
}

.radio .radio-outer {
    font-weight: 400 !important;
    margin-bottom: 0 !important;
}

.radio .radio-outer {
    padding: 5px;
    height: unset;
}

.check.check-outer {
    font-weight: 400 !important;
    justify-content: center;
}

.caption {
    font-size: 13px !important;
}

/* Fazer a tooltip do DS aparecer, sobrescrevendo os elementos de .tooltip do bootstrap  */
.tooltip {
    opacity: unset;
    font-family: Lato;
    margin: 0;
    position: static !important;
} 

.tooltip .tooltip-wrapper {
    z-index: 10070 !important;
}

/* Switch */
.margin-on-switch-outer .switch-outer {
    padding: 0 !important;
    margin: 10px 0;
}

.switch-outer .switch-label {
    margin-right: 8px !important;
}

.switch-outer .switch {
    margin-top: 2px;
    min-width: 22px;
}

.switch-outer label {
    margin-bottom: 0;
} /* Alinhar label e botão */

/* Ajustes para o select ficar menor em altura: */

div.textfield-outer div.ragnarok.textfield-wrapper button {
    margin-top: 8px !important;
}

div.menu-select-wrapper {
    width: 200px !important;
}

div.menu-select-wrapper div.textfield-outer div.textfield-wrapper {
    height: 40px !important;
    width: 200px !important;
}

div.menu-select-wrapper div.textfield-outer div.textfield-wrapper div.textfield-contents span.textfield-label {
    margin-top: -7px;
}

div.menu-select-wrapper div.menu-select button.menu-select-item {
    padding: 10px;
    font-size: 14px;
}

/* Garantir que o modal (dialog) ficará acima dos demais elementos */
.dialog-wrapper.open {
    z-index: 10;
}

/* Ajustar espaço abaixo do dialog em todos os casos */
.dialog-wrapper .dialog {
    max-height: 95vh !important;
    max-width: 90vw;
}

.sidebar .button-primary-medium, .sidebar .button-primary-medium:hover {
    box-shadow: none !important;
}

.navbar .label {
    font-size: 17px !important;
}

/* Gambiarra para permitir a rolagem dos itens da navbar do header em pequenas viewports */
.navbar .content {
    overflow: overlay;
    padding: 0;
}

.logo {
    width: 200px;
    min-width: 140px;
}

.table > :not(caption) > * > * {
    padding: 0.6rem 0.5rem;
}

div.textfield-outer div.ragnarok.textfield-wrapper {
    font-size: 14px;
}

/* Modificações nos botões do carrossel multi slide */
.react-multiple-carousel__arrow--left {
    left: 5px;
}

.react-multiple-carousel__arrow--right {
    right: 5px;
}

.react-multiple-carousel__arrow--left::before {
    content: none !important;
}

.react-multiple-carousel__arrow--right::before {
    content: none !important;
}

.react-multiple-carousel__arrow {
    min-width: unset;
}

.custom-carousel-arrow-button {
    justify-content: center !important;
}

.custom-carousel-arrow-button i {
    padding: 0px !important;
}

.react-multi-carousel-list {
    width: 100%;
}

.react-multi-carousel-dot--active button {
    background-color: #f58b4d !important;
}

.react-multi-carousel-dot button {
    border-color: #f58b4d;
    background-color: #F2F4F6;
    width: 9px;
    height: 9px;
}

.react-multi-carousel-dot button:hover:active {
    background: #f58b4d;
}

`;

export default GlobalStyles;
