import React, { useEffect, useState } from "react";
import { Select, SelectItem, Text, Card, TextField, Heading, Button, Switch } from "@aidron/aidron-ds";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import * as S from "./GeneralView.style";

import CustomText from "../../../components/StyledComponents/CustomText/CustomText.style";
import ResultError from "../../../components/ResultError/ResultError";

export default function GeneralView({ allUserEvents, getCompanies }) {
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const [userEvents, setUserEvents] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    initial: formatDate(firstDayOfCurrentMonth),
    end: formatDate(today),
  });
  const [stackGraph, setStackGraph] = useState(false);

  const areDatesValid = {
    initial: isValidDate(selectedDates.initial),
    end: isValidDate(selectedDates.end),
  };

  const data = getPagesData();

  const areAllCompaniesSelected = selectedCompanies.length === getCompanies().length;
  const areAllUsersSelected = selectedUsers.length === getUsers().length;
  const areAllPagesSelected = selectedPages.length === getPages().length;

  useEffect(() => {
    setSelectedCompanies(getCompanies());
    setSelectedUsers(getUsers());
    setSelectedPages(getPages());
  }, [allUserEvents]);

  function getUsers() {
    const users = allUserEvents.map(event => event.user.email);
    return [...new Set(users)];
  }

  function getPages() {
    const pages = allUserEvents.map(event => event.page);
    return [...new Set(pages)];
  }

  useEffect(() => {
    let results = allUserEvents;

    if (selectedCompanies.length) {
      results = results.filter(result => selectedCompanies.includes(result.user.company.name));
    }

    if (selectedUsers.length) {
      results = results.filter(result => selectedUsers.includes(result.user.email));
    }

    if (selectedPages.length) {
      results = results.filter(result => selectedPages.includes(result.page));
    }

    if (areDatesValid.initial) {
      const parsedInitialDate = new Date(parseDate(selectedDates.initial));
      results = results.filter(result => {
        const resultDate = new Date(result.createdAt);
        return resultDate >= parsedInitialDate;
      });
    }

    if (areDatesValid.end) {
      const parsedEndDate = new Date(parseDate(selectedDates.end));
      parsedEndDate.setHours(23, 59, 59, 999);
      results = results.filter(result => {
        const resultDate = new Date(result.createdAt);
        return resultDate <= parsedEndDate;
      });
    }

    setUserEvents(results);
  }, [allUserEvents, selectedCompanies, selectedUsers, selectedDates, selectedPages]);

  function getPagesData() {
    const result = {};
    userEvents.forEach(event => {
      const pageName = event.event;
      result[pageName] = result[pageName] ? result[pageName] + 1 : 1;
    });
    return result;
  }

  const sortedData = Object.fromEntries(Object.entries(data).sort(([, valueA], [, valueB]) => valueB - valueA));
  const xLabels = Object.keys(sortedData);

  function noStackGraphValues() {
    const values = Object.values(sortedData);
    return [
      {
        name: "Acessos",
        data: values,
        color: "#f58b4d",
      },
    ];
  }

  function stackGraphValues() {
    const colors = [
      "#f58b4d",
      "#BFBFBF",
      "#12a9b3", //azul claro
      "#aeaa10", //amarelo escuro
      "#9544d2", //roxo
      "#e52590", //rosa
      "#178751", //verde escuro
      "#eb3c3c", //vermelho
      "#5d62f6", //azul escuro
      "#b2f65d", //verde limão
      "#f8fa4f", //amarelo claro
    ];

    const usersFromEvents = userEvents.map(userEvent => userEvent.user.email);
    const users = [...new Set(usersFromEvents)];
    return users.map((user, userIndex) => {
      const data = xLabels.map(page => {
        const userPageEvents = userEvents.filter(event => event.event === page && event.user.email === user);
        return userPageEvents.length;
      });
      return { name: user, data, color: colors[userIndex] };
    });
  }

  const stackLegend = {
    enabled: true,
    align: "center",
    verticalAlign: "top",
    y: 0,
    floating: false,
    layout: "horizontal",
  };

  const noStackLegend = {
    enabled: false,
  };

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: xLabels,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Número de acessos",
      },
      stackLabels: {
        enabled: stackGraph,
      },
    },
    plotOptions: {
      column: {
        stacking: stackGraph ? "normal" : false,
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y;
          },
          style: {
            fontSize: "12px",
            color: "#666666",
            fontWeight: "normal",
          },
          inside: stackGraph,
        },
      },
    },
    legend: stackGraph ? stackLegend : noStackLegend,
    series: stackGraph ? stackGraphValues() : noStackGraphValues(),
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function parseDate(dateString) {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  }

  function isValidDate(dateString) {
    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const match = dateString.match(regex);

    if (!match) return false;

    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1;
    const year = parseInt(match[3], 10);

    const date = new Date(year, month, day);
    return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
  }
  return (
    <>
      <S.FirstSection>
        <Card>
          <S.Filters>
            <div>
              <S.FilterTitle>
                <Text size="sm" weight="bold" className="mb-2">
                  Empresas
                </Text>
                <S.ButtonContainer>
                  <Button
                    label={areAllCompaniesSelected ? "Desmarcar todas" : "Marcar todas"}
                    type="ghost"
                    onClick={() => {
                      if (areAllCompaniesSelected) setSelectedCompanies([]);
                      else setSelectedCompanies(getCompanies());
                    }}
                  />
                </S.ButtonContainer>
              </S.FilterTitle>
              <S.SelectContainer>
                <Select multiselect label="" handleChange={e => setSelectedCompanies(e)} selected={selectedCompanies}>
                  {getCompanies().map(company => (
                    <SelectItem key={company} value={company}>
                      {company}
                    </SelectItem>
                  ))}
                </Select>
              </S.SelectContainer>
            </div>

            <div>
              <S.FilterTitle>
                <Text size="sm" weight="bold" className="mb-2">
                  Usuários
                </Text>
                <S.ButtonContainer>
                  <Button
                    label={areAllUsersSelected ? "Desmarcar todos" : "Marcar todos"}
                    type="ghost"
                    onClick={() => {
                      if (areAllUsersSelected) setSelectedUsers([]);
                      else setSelectedUsers(getUsers());
                    }}
                  />
                </S.ButtonContainer>
              </S.FilterTitle>
              <S.SelectContainer>
                <Select multiselect label="" handleChange={e => setSelectedUsers(e)} selected={selectedUsers}>
                  {getUsers().map(user => (
                    <SelectItem key={user} value={user}>
                      {user}
                    </SelectItem>
                  ))}
                </Select>
              </S.SelectContainer>
            </div>

            <div>
              <S.FilterTitle>
                <Text size="sm" weight="bold" className="mb-2">
                  Páginas
                </Text>
                <S.ButtonContainer>
                  <Button
                    label={areAllPagesSelected ? "Desmarcar todas" : "Marcar todas"}
                    type="ghost"
                    onClick={() => {
                      if (areAllPagesSelected) setSelectedPages([]);
                      else setSelectedPages(getPages());
                    }}
                  />
                </S.ButtonContainer>
              </S.FilterTitle>
              <S.SelectContainer>
                <Select multiselect label="" handleChange={e => setSelectedPages(e)} selected={selectedPages}>
                  {getPages().map(user => (
                    <SelectItem key={user} value={user}>
                      {user}
                    </SelectItem>
                  ))}
                </Select>
              </S.SelectContainer>
            </div>

            <S.DateContainer border={!areDatesValid.initial}>
              <TextField
                onChange={e => setSelectedDates({ ...selectedDates, initial: e })}
                label="Data de início"
                placeholder="dd/mm/aaaa"
                value={selectedDates.initial}
              />
              {!areDatesValid.initial && (
                <CustomText color="darkYellow" size="xs">
                  Insira uma data no formato "dd/mm/aaaa" anterior a hoje.
                </CustomText>
              )}
            </S.DateContainer>

            <S.DateContainer border={!areDatesValid.end}>
              <TextField
                onChange={e => setSelectedDates({ ...selectedDates, end: e })}
                label="Data de fim"
                placeholder="dd/mm/aaaa"
                value={selectedDates.end}
              />
              {!areDatesValid.end && (
                <CustomText color="darkYellow" size="xs">
                  Insira uma data no formato "dd/mm/aaaa" anterior ou igual a hoje.
                </CustomText>
              )}
            </S.DateContainer>
          </S.Filters>
        </Card>

        {/* <Card style={{ height: "fit-content" }}>
      <S.Index>
        <Text size="sm" weight="bold" className="mb-2">
          Número de logins
        </Text>
        <CustomHeading size="sm" weight="bold" className="mb-2">
          {loginEvents.length.toString()}
        </CustomHeading>
      </S.Index>
    </Card> */}
      </S.FirstSection>

      {userEvents.length ? (
        <S.SecondSection>
          <Card style={{ height: "fit-content" }}>
            <S.ContentContainer>
              {userEvents.length ? (
                <>
                  <Heading size="sm" className="mb-2">
                    Páginas e componentes visualizados
                  </Heading>
                  <S.SwitchContainer>
                    <Switch handleChange={option => setStackGraph(option)} value={stackGraph}>
                      Agrupar por usuário
                    </Switch>
                  </S.SwitchContainer>
                  <S.ChartContainer>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                  </S.ChartContainer>
                </>
              ) : (
                <Heading size="sm">Os usuários selecionados não acessaram páginas da plataforma.</Heading>
              )}
            </S.ContentContainer>
          </Card>

          <Card>
            <S.ContentContainer>
              <Heading size="sm" className="mb-2">
                Atividades
              </Heading>

              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Empresa</th>
                    <th scope="col">Usuário</th>
                    <th scope="col">Data</th>
                    <th scope="col">Página ou componente</th>
                  </tr>
                </thead>
                <tbody>
                  {userEvents.map(event => (
                    <tr>
                      <td>{event.user.company.name}</td>
                      <td>{event.user.email}</td>
                      <td>{formatDate(event.createdAt)}</td>
                      <td>{event.event}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </S.ContentContainer>
          </Card>
        </S.SecondSection>
      ) : (
        <ResultError />
      )}
    </>
  );
}
