import axios from "./BaseService";

const API_URL = process.env.REACT_APP_API_URL || "https://teste.aidron.ai";
const FILES_URL = `${API_URL}/files`;

export async function createFile(newFile, token) {
  const headers = { authorization: token };
  const response = await axios.post(
    FILES_URL,
    { files: [newFile] },
    {
      headers,
    }
  );
  return response.data;
}

export async function saveFile(newFile, token) {
  const headers = { authorization: token };
  const response = await axios.patch(
    FILES_URL,
    { files: [newFile] },
    {
      headers,
    }
  );
  return response.data;
}
