import React from "react";
import { Button, Text } from "@aidron/aidron-ds";

import * as S from "./AIButton.syle";

export default function AIButton({ onlyAIPredictions, setOnlyAIPredictions }) {
  return (
    <S.AIButtonContainer>
      <Button
        onClick={() => setOnlyAIPredictions(!onlyAIPredictions)}
        label=""
        type={onlyAIPredictions ? "filled" : "outlined"}
        size="sm"
      >
        IA
        <S.TooltipBox>
          <S.Tooltip className="custom-tooltip">
            <Text size="xs">Filtrar Ajuste IA</Text>
          </S.Tooltip>
        </S.TooltipBox>
      </Button>
    </S.AIButtonContainer>
  );
}
