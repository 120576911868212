import React from "react";

import * as S from "./AdjustHeader.style";

import FiltersAndSums from "../AdjustSummary/FiltersAndSums/FiltersAndSums";
import CustomHeading from "../../../components/StyledComponents/CustomHeading/CustomHeading.style";

export default function AdjustHeader({
  products,
  selectedGroup,
  setSelectedGroupValue,
  filteredPredictions,
  originalMonthColumns,
  selectedMonthsValue,
  setSelectedMonthsValue,
  formatIndex,
}) {
  return (
    <S.Container>
      <CustomHeading size="lg" margin="0">
        Ajustar Plano de Vendas
      </CustomHeading>

      <FiltersAndSums
        products={products}
        selectedGroup={selectedGroup}
        setSelectedGroupValue={setSelectedGroupValue}
        filteredPredictions={filteredPredictions}
        originalMonthColumns={originalMonthColumns}
        selectedMonthsValue={selectedMonthsValue}
        setSelectedMonthsValue={setSelectedMonthsValue}
        formatIndex={formatIndex}
      />
    </S.Container>
  );
}
